export const skills = {
  mySkills: [
    "html5-plain",
    "css3-plain",
    "sass-original",
    "javascript-plain",
    "react-original",
    "nodejs-plain",
    "express-original",
    "php-plain",
    "mysql-plain",
    "postgresql-plain",
    "mongodb-plain",
    "figma-plain",
    "git-plain",
  ],
};
